define('Mediator', function() {

  'use strict';

  function Mediator() {
    this._subscribers = {};
  }


  Object.defineProperties(Mediator.prototype, {

    /**
     * Register a new event listener with a function assciated with it
     * @param  {String}   name
     * @param  {Function} fn
     */
    listen: {
      value: function(name, fn, ctx) {

        var isNotValid = requirementsCheck(arguments);
        if (isNotValid)
          throw new Error(isNotValid);

        if (!this._subscribers[name]) {
          this._subscribers[name] = [];
        }


        var index = this._subscribers[name].indexOf(fn);
        if (index > -1) return;

        // store the context if passed in
        if (ctx)
          fn.ctx = ctx;

        this._subscribers[name].push(fn);

      },
      enumarable: true
    },

    /**
     * Registers a new event listener which gets flaged to only be called once
     * @param  {String}   name
     * @param  {Function} fn
     */
    listenOnce: {
      value: function(name, fn, ctx) {
        var isNotValid = requirementsCheck(arguments);
        if (isNotValid)
          throw new Error(isNotValid);

        if (!this._subscribers[name])
          this._subscribers[name] = [];

        fn.once = true;

        if (ctx)
          fn.ctx = ctx;
        this.listen.call(this, name, fn);
      },
      enumarable: true
    },

    /**
     * Remove a specified listener
     * @param  {[type]}   name
     * @param  {Function} fn
     * @return {[type]}
     */
    silence: {
      value: function(name, fn) {
        var isNotValid = requirementsCheck(arguments);
        if (isNotValid)
          throw new Error(isNotValid);

        if (!this._subscribers[name]) return;

        var index = this._subscribers[name].indexOf(fn);

        if (index > -1)
          this._subscribers[name].splice(index, 1);
      },
      enumarable: true
    },

    /**
     * Fires all the the listeners of a specified type
     * @param  {String}   name
     * @param  {Object}   [obj]  Pass data to the listeners
     * @return {[type]}        [description]
     */
    notify: {
      value: function(name, obj) {
        if (!this._subscribers[name]) return;

        var sub = this._subscribers[name],
          len = sub.length;

        for (var i = 0; i < len; i++) {
          if (sub[i].once) {
            //call the function with context stored in function or else default the context to current obj.
            sub[i].call(sub[i].ctx || this, obj);
            sub.splice(i, 1);
            continue;
          }
          sub[i].call(sub[i].ctx || this, obj);
        }
      },
      enumarable: true
    },

    /**
     * Completely removes a listenr typr along with all its listeners
     * @param  {String}   name [description]
     */
    kill: {
      value: function(name) {
        if (!this._subscribers[name]) return;
        delete this._subscribers[name];
      },
      enumarable: true
    }
  });


  var requirementsCheck = function(args) {
    var msg = "The arugments you passed don\'t meet the specification";
    if (args.length < 2)
      return msg;

    if (typeof args[0] !== 'string' && args.length)
      return msg;

    if (typeof args[1] !== 'function' && args.length)
      return msg;

    return false;
  };



  return Mediator;

});
